.root {
  display: inline-flex;
  flex-direction: row;
  position: relative;
  align-items: stretch;
  justify-content: center;
  width: auto;
  height: auto;
  max-width: 100%;
  justify-self: flex-start;
  padding: 0px;
}
.root___focusVisibleWithin {
  outline: none;
}
.root___focusVisibleWithin_shape_rounded {
  outline: none;
}
.clickEventReporter:global(.__wab_instance) {
  max-width: 100%;
  object-fit: cover;
}
.button {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background: var(--token-G4F_mTJaqcwE);
  transition-property: all;
  transition-duration: 0.15s;
  box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.2);
  transition-timing-function: ease-out;
  filter: none;
  -webkit-transition-property: all;
  -webkit-transition-timing-function: ease-out;
  -webkit-transition-duration: 0.15s;
  border-radius: 6px;
  padding: 14px 20px;
}
.buttonshowStartIcon {
  padding-left: 16px;
}
.buttonshowEndIcon {
  padding-right: 16px;
}
.buttonisDisabled {
  cursor: not-allowed;
  opacity: 0.6;
}
.buttonshape_rounded {
  padding-left: 20px;
  padding-right: 20px;
  min-width: 100px;
  border-radius: 999px;
}
.buttonshape_round {
  border-radius: 50%;
  padding: 12px;
}
.buttonshape_sharp {
  border-radius: 0px;
}
.buttonsize_compact {
  padding: 6px 16px;
}
.buttonsize_minimal {
  padding: 0px 2px;
}
.buttoncolor_placeitPurple {
  background: radial-gradient(
    ellipse 100% 100% at 50% 50%,
    var(--token-yV5G9sNB3cwe) 0%,
    #6318b4 100%
  );
}
.buttoncolor_lightGreen {
  background: #c7f54e;
}
.buttoncolor_orange {
  background: #ff6200;
}
.buttoncolor_red {
  background: #e54d2e;
}
.buttoncolor_sand {
  background: #717069;
}
.buttoncolor_white {
  background: #ffffff;
}
.buttoncolor_softBlue {
  background: #edf6ff;
}
.buttoncolor_flatGreen {
  background: #39aa04;
  box-shadow: -6px 8px 0px 0px #000000;
  cursor: pointer;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  filter: none;
  border: 3px solid #000000;
}
.buttoncolor_softYellow {
  background: #fffbd1;
}
.buttoncolor_softRed {
  background: #fff0ee;
}
.buttoncolor_softSand {
  background: #eeeeec;
}
.buttoncolor_clear {
  background: #ffffff00;
}
.buttoncolor_link {
  background: #ffffff00;
}
.buttonshowStartIcon_shape_rounded {
  padding-left: 16px;
}
.buttonshape_rounded_showEndIcon {
  padding-right: 16px;
}
.buttonshape_round_size_compact {
  padding: 6px;
}
.root .button___focusVisibleWithin {
  cursor: pointer;
  outline: none;
}
.root:hover .button {
  filter: brightness(75%) contrast(171%);
}
.root:active .button {
  box-shadow: 0px 1px 8px 0px #0000004d;
  cursor: pointer;
  filter: brightness(67%) contrast(200%);
}
.rootshape_rounded .button___focusVisibleWithin_shape_rounded {
  outline: none;
}
.rootcolor_sand:hover .buttoncolor_sand {
  background: #7f7e77;
}
.rootcolor_sand:active .buttoncolor_sand {
  background: #a1a09a;
}
.rootcolor_white:hover .buttoncolor_white {
  background: #ffef5c;
}
.rootcolor_white:active .buttoncolor_white {
  background: #f0c000;
}
.rootcolor_softBlue:hover .buttoncolor_softBlue {
  background: #e1f0ff;
}
.rootcolor_softBlue:active .buttoncolor_softBlue {
  background: #cee7fe;
}
.rootcolor_flatGreen:active .buttoncolor_flatGreen {
  box-shadow: -4px 5px 0px 0px #000000;
}
.rootcolor_softYellow:active .buttoncolor_softYellow {
  background: #fef2a4;
}
.rootcolor_softYellow:hover .buttoncolor_softYellow {
  background: #fff8bb;
}
.rootcolor_softRed:active .buttoncolor_softRed {
  background: #fdd8d3;
}
.rootcolor_softRed:hover .buttoncolor_softRed {
  background: #ffe6e2;
}
.rootcolor_softSand:hover .buttoncolor_softSand {
  background: #e9e9e6;
}
.rootcolor_softSand:active .buttoncolor_softSand {
  background: #e3e3e0;
}
.rootcolor_clear:hover .buttoncolor_clear {
  background: #e9e9e6;
}
.rootcolor_clear:active .buttoncolor_clear {
  background: #e3e3e0;
}
.rootcolor_link:hover .buttoncolor_link {
  background: #ffffff00;
}
.rootcolor_link:active .buttoncolor_link {
  background: #ffffff00;
}
.contentContainer {
  display: flex;
  position: relative;
  flex-direction: row;
}
.contentContainer > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-left: calc(0px - 2px);
  width: calc(100% + 2px);
}
.contentContainer > :global(.__wab_flex-container) > *,
.contentContainer > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.contentContainer > :global(.__wab_flex-container) > picture > img,
.contentContainer
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 2px;
}
.root .contentContainer___focusVisibleWithin {
  outline: none;
}
.root:hover .contentContainer {
  flex-direction: row;
  filter: none;
}
.root:hover .contentContainer > :global(.__wab_flex-container) {
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: calc(0px - 0px);
  height: calc(100% + 0px);
}
.root:hover .contentContainer > :global(.__wab_flex-container) > *,
.root:hover
  .contentContainer
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > *,
.root:hover .contentContainer > :global(.__wab_flex-container) > picture > img,
.root:hover
  .contentContainer
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 0px;
}
.root:active .contentContainer {
  filter: none;
}
.startIconContainer {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
}
.startIconContainershowStartIcon {
  display: flex;
}
.slotTargetStartIcon {
  color: #ededec;
}
.slotTargetStartIconcolor_orange {
  color: #35290f;
}
.slotTargetStartIconcolor_white {
  color: #35290f;
}
.slotTargetStartIconcolor_softBlue {
  color: #006adc;
}
.slotTargetStartIconcolor_flatGreen {
  color: #18794e;
}
.slotTargetStartIconcolor_softYellow {
  color: #946800;
}
.slotTargetStartIconcolor_softRed {
  color: #ca3214;
}
.slotTargetStartIconcolor_softSand {
  color: #706f6c;
}
.slotTargetStartIconcolor_clear {
  color: #1b1b18;
}
.slotTargetStartIconcolor_link {
  color: #0091ff;
}
.rootcolor_link:hover .slotTargetStartIconcolor_link {
  color: #0081f1;
}
.rootcolor_link:active .slotTargetStartIconcolor_link {
  color: #006adc;
}
.svg__lGhp5 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.slotTargetChildren {
  color: #ffffff;
  font-weight: 500;
  white-space: pre;
  font-size: var(--token--qjKwCUH1);
}
.slotTargetChildren > *,
.slotTargetChildren > :global(.__wab_slot) > *,
.slotTargetChildren > :global(.__wab_slot) > :global(.__wab_slot) > *,
.slotTargetChildren
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > *,
.slotTargetChildren > picture > img,
.slotTargetChildren > :global(.__wab_slot) > picture > img,
.slotTargetChildren
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > picture
  > img,
.slotTargetChildren
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > picture
  > img {
  transition-property: all;
  transition-duration: 1s;
  -webkit-transition-property: all;
  -webkit-transition-duration: 1s;
}
.slotTargetChildrensize_compact {
  font-size: var(--token-DckCCvtwain);
}
.slotTargetChildrensize_minimal {
  font-size: var(--token-jFlfsKM8_wXg);
}
.slotTargetChildrencolor_placeitPurple {
  color: #ffffff;
}
.slotTargetChildrencolor_lightGreen {
  color: #000000;
}
.slotTargetChildrencolor_orange {
  color: #ffffff;
}
.slotTargetChildrencolor_red {
  color: #ffffff;
}
.slotTargetChildrencolor_sand {
  color: #ffffff;
}
.slotTargetChildrencolor_white {
  color: #1b1b18;
}
.slotTargetChildrencolor_softBlue {
  color: #006adc;
}
.slotTargetChildrencolor_softYellow {
  color: #946800;
}
.slotTargetChildrencolor_softRed {
  color: #ca3214;
}
.slotTargetChildrencolor_softSand {
  color: #1b1b18;
}
.slotTargetChildrencolor_clear {
  color: #1b1b18;
}
.slotTargetChildrencolor_link {
  color: #0091ff;
}
.root .slotTargetChildren___focusVisibleWithin > *,
.root .slotTargetChildren___focusVisibleWithin > :global(.__wab_slot) > *,
.root
  .slotTargetChildren___focusVisibleWithin
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > *,
.root
  .slotTargetChildren___focusVisibleWithin
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > *,
.root .slotTargetChildren___focusVisibleWithin > picture > img,
.root
  .slotTargetChildren___focusVisibleWithin
  > :global(.__wab_slot)
  > picture
  > img,
.root
  .slotTargetChildren___focusVisibleWithin
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > picture
  > img,
.root
  .slotTargetChildren___focusVisibleWithin
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > picture
  > img {
  outline: none;
}
.rootcolor_softBlue:hover .slotTargetChildrencolor_softBlue {
  color: #000000;
}
.rootcolor_link:hover .slotTargetChildrencolor_link {
  color: #0081f1;
}
.rootcolor_link:hover .slotTargetChildrencolor_link > :global(.__wab_text),
.rootcolor_link:hover
  .slotTargetChildrencolor_link
  > :global(.__wab_expr_html_text),
.rootcolor_link:hover
  .slotTargetChildrencolor_link
  > :global(.__wab_slot-string-wrapper),
.rootcolor_link:hover
  .slotTargetChildrencolor_link
  > :global(.__wab_slot)
  > :global(.__wab_text),
.rootcolor_link:hover
  .slotTargetChildrencolor_link
  > :global(.__wab_slot)
  > :global(.__wab_expr_html_text),
.rootcolor_link:hover
  .slotTargetChildrencolor_link
  > :global(.__wab_slot)
  > :global(.__wab_slot-string-wrapper),
.rootcolor_link:hover
  .slotTargetChildrencolor_link
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_text),
.rootcolor_link:hover
  .slotTargetChildrencolor_link
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_expr_html_text),
.rootcolor_link:hover
  .slotTargetChildrencolor_link
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_slot-string-wrapper),
.rootcolor_link:hover
  .slotTargetChildrencolor_link
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_text),
.rootcolor_link:hover
  .slotTargetChildrencolor_link
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_expr_html_text),
.rootcolor_link:hover
  .slotTargetChildrencolor_link
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_slot-string-wrapper) {
  text-decoration-line: underline;
}
.rootcolor_link:active .slotTargetChildrencolor_link {
  color: #006adc;
}
.text___5ZQfV {
  padding-top: 0px;
  filter: none;
}
.endIconContainer {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
}
.endIconContainershowEndIcon {
  display: flex;
}
.slotTargetEndIcon {
  color: #ededec;
}
.slotTargetEndIconcolor_orange {
  color: #35290f;
}
.slotTargetEndIconcolor_white {
  color: #35290f;
}
.slotTargetEndIconcolor_softBlue {
  color: #006adc;
}
.slotTargetEndIconcolor_flatGreen {
  color: #18794e;
}
.slotTargetEndIconcolor_softYellow {
  color: #946800;
}
.slotTargetEndIconcolor_softRed {
  color: #ca3214;
}
.slotTargetEndIconcolor_softSand {
  color: #706f6c;
}
.slotTargetEndIconcolor_clear {
  color: #1b1b18;
}
.slotTargetEndIconcolor_link {
  color: #0091ff;
}
.rootcolor_link:hover .slotTargetEndIconcolor_link {
  color: #0081f1;
}
.rootcolor_link:active .slotTargetEndIconcolor_link {
  color: #006adc;
}
.svg__pGuet {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}

.buttonMegaUltraBase:global(.__wab_instance) {
  max-width: 100%;
}
.svg__kcaTf {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text {
  padding-top: 0px;
}
.svg__oL89 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
